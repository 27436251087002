import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import moment from 'moment';

import 'moment/locale/it';

import Styles from './BlogPost.styles';
import Utils from '@utils/';

const useStyles = createUseStyles(Styles);

const BlogPost = ({ post, ...props }) => {
  const classes = useStyles(props);

  const image = post.frontmatter.image
    ? post.frontmatter.image.replace('static/', '')
    : null;

  return (
    <div className={classes.container}>
      <div className={classes.date}>
        <span>Liveflow</span>|{' '}
        <span>
          {moment(post.frontmatter.date).locale('it').format('DD MMMM YYYY')}
        </span>
      </div>

      {post.frontmatter.category && (
        <div className={classes.categories}>
          <div className={`${classes.category} ${classes.categoryActive}`}>
            <a
              className={`${classes.categoryLink} ${classes.categoryLinkActive}`}
              href={`#${post.frontmatter.category}`}
              onClick={e => {
                e.preventDefault();
              }}
            >
              {post.frontmatter.category}
            </a>
          </div>
        </div>
      )}
      {image && (
        <img
          src={`/${image}`}
          alt={post.frontmatter.title}
          className={classes.image}
        />
      )}
      <div
        className={`${classes.description} blog-post-description`}
        dangerouslySetInnerHTML={Utils.createMarkup(post.html)}
      ></div>

      <script type="application/ld+json">
        {`{
            "@context": "http://schema.org",
            "@type": "Article",
            "headline": "${post.frontmatter.title}",
            "author": "Liveflow",
            "datePublished": "${post.frontmatter.date}",
            "dateModified": "${post.frontmatter.date}",
            "description": "${post.frontmatter.description}",
            "image": "https://liveflow.it/${image}",
            "publisher": {
              "@type": "Organization",
              "name": "Liveflow",
              "logo": {
                "@type": "ImageObject",
                "url": "https://www.liveflow.it/logo.png"
              }
            }
          }`}
      </script>
    </div>
  );
};

BlogPost.propTypes = {
  post: PropTypes.object,
};

export default BlogPost;
