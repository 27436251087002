import Colors from '@utils/colors.js';
import Breakpoints from '@utils/breakpoints';

const Styles = {
  back: {
    marginBottom: '40px',
    display: 'inline-block',
    color: Colors.base + ' !important',
    textDecoration: 'none !important',
    fontWeight: 700,
    fontSize: '12px',
    [`@media (max-width: ${Breakpoints.m})`]: {
      marginBottom: '20px',
    },
  },
  backArrow: {
    display: 'inline-block',
    marginRight: '12px',
  },
};

export default Styles;
