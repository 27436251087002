import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '@layout/Layout';
import PageContent from '@layout/PageContent';
import PageContainer from '@layout/PageContainer';

import Seo from '@components/Seo/Seo';
import PageHeader from '@components/PageHeader/PageHeader';
import Back from '@components/Back/Back';
import BlogPost from '@components/BlogPost/BlogPost';
import Newsletter from '@components/Newsletter/Newsletter';

const PostTemplate = ({ data, location }) => {
  const d = data.markdownRemark;
  return d ? (
    <Layout>
      <PageContainer noBottomMargin>
        <Seo
          title={d.frontmatter.title}
          description={d.frontmatter.description}
          url={location.pathname}
        ></Seo>
        <PageContent mid>
          <Back to="/blog" label="TORNA INDIETRO" />
        </PageContent>
        <PageHeader title={d.frontmatter.title} center nomargin />
        <BlogPost post={d} />
        <Newsletter />
      </PageContainer>
    </Layout>
  ) : null;
};

PostTemplate.propTypes = {
  data: PropTypes.object,
};

export default PostTemplate;

const query = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        path
        date(formatString: "YYYY-MM-DD")
        title
        category
        category_name
        category_slug
        image
        description
      }
      html
    }
  }
`;

export { query };
