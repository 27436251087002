import Dimensions from '@utils/dimensions';

const Styles = {
  container: {
    maxWidth: Dimensions.contentMaxWidth,
    margin: '0 auto',
  },
  containerMid: {
    maxWidth: Dimensions.contentMidWidth,
  },
};

export default Styles;
