import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';

import Styles from './PageContent.styles';

const useStyles = createUseStyles(Styles);

const PageContent = ({ children, mid, ...props }) => {
  const classes = useStyles(props);

  return (
    <div className={`${classes.container} ${mid ? classes.containerMid : ''}`}>
      {children}
    </div>
  );
};

PageContent.propTypes = {
  children: PropTypes.any,
};

export default PageContent;
